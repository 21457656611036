<template>
	<div>
		<v-container fluid>
			<v-row v-for="product in products" :key="product.id" class="align-center">

				<v-col cols="6" class="grey-dark">
					<span class="name">{{ displayQuantity(product.quantity) }} {{ product.name }}</span>
				</v-col>

				<v-col cols="6" class="text-right" v-if="product.price">
					<div>{{ $options.filters.currency(calculateProductTotal(product)) }}</div>
				</v-col>
			</v-row>

			<hr class="mt-5 mb-5 divider">

			<v-row class="total" v-if="subTotal">
				<v-col>
					<div>{{ $t('product.subTotal') }} :</div>
				</v-col>
				<v-col class="text-right">
					<div>{{ $options.filters.currency(subTotal) }}</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	export default {
		name: "ProductsSubTotalList",
		props: {
			products: {
				type: Array,
				required : true,
			},
		},
		computed: {
			subTotal() {
				return this.products.reduce((sum, product) => {
					return sum + product.price;
				}, 0).toFixed(2);
			}
		},
		methods: {
			calculateProductTotal(product) {
				return product.price.toFixed(2);
			},
			displayQuantity(quantity) {
				return `+${quantity}`;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.grey-light {
		color: $grey-light;
	}
	.grey-dark {
		color: $grey-dark;
	}
	.price {
		font-weight: bold;
		color: $grey-dark;
	}
	.divider {
		border-top: 1px solid $grey-light;
	}
	.total {
		font-size: 1.3rem;
		font-weight: 600;
	}
</style>
