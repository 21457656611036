<template>
	<v-dialog v-model="modalOpen" width="700" v-on:click:outside="closeModal">
		<v-card class="pa-4" >
			<v-btn
				class="close-btn float-right d-flex sm-image-close-btn"
				icon
				@click="closeModal"
				style="position:relative; z-index: 1">
				<v-icon class="theme--dark">mdi-close</v-icon>
			</v-btn>
			<v-card-text class="pa-0" >
				<ProductCard
					:product="product"
					:productsSelected="productsSelected"
					:is-in-modal="true"
					@quantityChanged="updateQuantity"
				/>
				</v-card-text>
				<v-card-actions class="d-flex justify-end">
					<div class="d-flex justify-end">
						<v-btn class="cancel-button mr-3" outlined @click="closeModal">
							{{ $t('btn.nextTime') }}
						</v-btn>
						<v-btn
							v-if="productIsTimeBased"
							class="add-to-cart-button" outlined
							:disabled="!productHasQuantity"
							@click="goNext"
						>
							{{ $t('tag.chooseVisitingDate') }}
						</v-btn>
						<v-btn
							v-else
							class="add-to-cart-button" outlined
							:disabled="!productHasQuantity"
							@click="addToCart"
						>
							{{ $t('btn.addToCart') }}
						</v-btn>
					</div>
				</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import BackgroundImage from '@/assets/images/background.svg'
import ProductCard from "@/components/ProductV2/ProductCard";

export default {
	components: {ProductCard},
	props: {
		product: {
			type: Object,
			required: true
		},
		productsSelected: {
			type: Map,
			required: true
		},
		isOpen: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			modalOpen: this.isOpen
		};
	},
	watch: {
		isOpen(newVal) {
			this.modalOpen = newVal;
			console.log(this.product)
		}
	},
	computed: {
		productImg() {
			return this.product.data.image || BackgroundImage;
		},
		productHasQuantity() {
			return this.productsSelected.get(this.product.data.id)?.quantity > 0;
		},
		productIsTimeBased() {
			return this.product.data.event_group_id;
		},
	},
	methods: {
		updateQuantity(product, quantity) {
			this.$emit('quantityChanged', product, quantity);
		},
		closeModal() {
			this.modalOpen = false;
			//assign hash to url for marketing purposes
			history.replaceState(null, null, window.location.pathname);

			this.$emit('cancel');
		},
		goNext() {
			this.closeModal()
			this.$emit('goNext');
		},
		addToCart() {
			this.closeModal()
			this.$emit('addToCart')
		}
	}
};
</script>

<style scoped>
</style>
