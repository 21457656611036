<template>
	<div class="products-time-picker">
		<h4 class="mb-2">{{ productName }}</h4>
		<div class="select-button d-flex flex-column"
			:class="isPanelOpen ? 'rounded-t-lg' : 'rounded-lg'"
			@click="togglePanel">
			<div :class="'d-flex justify-space-between align-center w-100' + (date ? '': ' v-card--disabled')" >
				<span v-if="!date">{{ $t('product.select_date') }}</span>
				<span v-else-if="!selectedTime">{{ $t('product.select_time') }}</span>
				<span v-else>{{ selectedTime }}</span>
				<v-icon>{{ isPanelOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
			</div>
		</div>

		<div v-if="isPanelOpen"
			ref="selectionPanel"
			class="selection-panel rounded-b-lg"
			:style="{ height: panelHeight + 'px', width: '100%' }">

			<v-skeleton-loader
				v-if="loading"
				type="list-item"
				class="my-4"
				:width="'100%'"
				:height="100"
			/>
			<template v-else>
				<div v-for="(slot, index) in timeSlots"
					:key="index"
					class="time-slot-item"
					@click="selectTime(slot)">
					<span class="time-range">{{ slot.period }}</span>
					<span class="places-left">{{ slot.places }} {{ $t('product.places_left') }}</span>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import {EComService} from '@connectngo/sdk';
	export default {
		name: "ProductsTimePicker",
		props: {
			date: {
				type: String,
			},
			eventGroupId: {
				type: Number
			},
			productName: {
				type: String
			}
		},
		data() {
			return {
				loading: true,
				isPanelOpen: false,
				selectedTime: null,
				panelHeight: 0,
				timeSlots:[]
			};
		},
		mounted() {
			if(this.date) {
				this.fetchAvailabilities();
			}
		},
		watch : {
			date() {
				this.selectedTime = null;
				this.fetchAvailabilities();
			}
		},
		methods: {
			fetchAvailabilities() {
				this.loading = true;
				const startDate = moment(this.date).startOf('day').unix();
				const endDate = moment(this.date).endOf('day').unix();

				new EComService().getEventAvailabilities(this.$props.eventGroupId, startDate, endDate)
					.then(response => { this.formatDataForSelect(response)})
					.catch(error => this.$handleError(this, error))
					.finally(() => (this.loading = false));
			},
			formatDataForSelect(datas) {
				this.timeSlots = [];
				datas.forEach(( eventAvailability ) => {
					if (eventAvailability.data.day === this.date) {
						const available = eventAvailability.data.available;
						eventAvailability.data.events.forEach(( event ) => {
							this.timeSlots.push({
								period: event.data.period,
								places: available,
								start_ts: event.data.start_ts,
								end_ts: event.data.end_ts,
								event_id : event.data.id
							});
						});
					}
				});
			},
			togglePanel() {
				if (!this.date) {
					return;
				}
				if (this.isPanelOpen) {
					this.isPanelOpen = false;
					this.panelHeight = 0;
				} else {
					this.isPanelOpen = true;
					this.$nextTick(() => {
						const panel = this.$refs.selectionPanel;
						this.panelHeight = panel.scrollHeight;
					});
				}
			},
			selectTime(slot) {
				this.selectedTime = slot.period;
				this.isPanelOpen = false;
				this.panelHeight = 0;

				this.$emit("timeSelected", {
					period: slot.period,
					places: slot.places,
					start_ts: slot.start_ts,
					end_ts: slot.end_ts,
					event_id: slot.event_id
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.products-time-picker {
		position: relative;
		width: 100%;
	}
	.select-button {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 16px;
		border: 1px solid $grey-light;
		cursor: pointer;
		background-color: white;
	}
	.selection-panel {
		background-color: white;
		overflow: hidden;
		transition: height 0.3s ease-out;
		border: 1px solid $grey-light;
		border-top: none;
		height: 0;
		width: 100%;
	}
	.selection-panel.pt-3 {
		padding-top: 16px;
	}
	.time-slot-item {
		display: flex;
		justify-content: space-between;
		padding: 8px 16px;
		cursor: pointer;
	}
	.time-slot-item:hover {
		background-color: #f0f0f0;
	}
	.time-range {
		font-weight: bold;
	}
	.places-left {
		color: gray;
		font-size: 0.9rem;
	}
</style>
