<template>
	<v-card :class="['pa-6', 'rounded-lg', { 'no-border': isInModal }]" outlined>
		<!-- Modal only title-->
		<v-card-title
			class="text-center pt-0 pl-0 text-grey-dark text-h6"
			v-if="isInModal">
			{{ productTitle }}
		</v-card-title>

		<v-row>
			<v-col cols="12"
				:md="isInModal ? '12' : '6'"
				class="d-flex align-self-stretch">
				<v-img
				:src="productImg"
				alt="Image"
				:aspect-ratio="16 /9"
				class="rounded-lg"
				></v-img>
			</v-col>
			<v-col cols="12"
					v-if="isInModal && hasDescription()"
					md="12"
					class="d-flex align-self-stretch">
				<p v-html="getDescription()"></p>
			</v-col>
			<v-col cols="12"
					:md="isInModal ? 12 : 6"
					class="d-flex flex-column justify-space-between">
				<v-card-title
					class="text-left pa-0 mb-4 text-grey-dark text-h6 title-text">
					{{ productTitle }}
				</v-card-title>

				<v-card-text
					v-if="truncatedDescription && !isInModal"
					class="text-left description-text pa-0" v-html="truncatedDescription">
				</v-card-text>

				<v-spacer></v-spacer>
				<v-row justify="end" class="counter-row mt-auto">
					<v-btn icon @click="decrement" class="circular-button mx-1">
						<v-icon>mdi-minus</v-icon>
					</v-btn>
					<span class="counter-value mx-12">{{ currentQuantity }}</span>
					<v-btn icon @click="increment" class="circular-button mx-1">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-row>
			</v-col>
		</v-row>

		<v-row class="mt-4" v-if="product.data.min_quantity || product.data.max_quantity">
			<span class="ml-auto mr-3 text-caption text-grey-light">
				{{ $t('product.quantity') }}&nbsp;
				(<span v-if="product.data.min_quantity">min {{ product.data.min_quantity }};&nbsp;</span>
				<span>max {{ product.data.max_quantity }}</span>
			)</span>
		</v-row>

		<v-row class="mt-4">
			<v-col cols="12" md="6"></v-col>
			<v-col cols="12" md="6" class="d-flex justify-between align-center">
				<v-btn
					v-if="!isInModal"
					@click="openModal"
					outlined
					class="elevation-0 text-caption pr-1 pl-1 pt-0 pb-0 border-sm rounded-md"
					>
					{{ $t('product.find_out_more') }}
				</v-btn>
				<div class="ml-auto">
					<span class="mr-2 text-caption text-grey-dark">{{ $t('product.starting_from') }} :</span>
					<span class="price-value text-h6 text-grey-dark">{{  $options.filters.currency(product.data.price) }}</span>
				</div>
			</v-col>
		</v-row>

	</v-card>
</template>

<script>
	import BackgroundImage from '@/assets/images/background.svg'
	import { ProductModel } from '@connectngo/sdk'
	import Text from '@/utils/Text'

	export default {
		name: "ProductCard",
		props: {
			product: {
				type: ProductModel,
				required: true
			},
			productsSelected: {
				type: Map,
				required: true
			},
			isInModal: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			currentQuantity() {
				return this.productsSelected.get(this.product.data.id)?.quantity || 0;
			},
			productTitle() {
				return this.$options.filters.translatable(this.product.data.name, this.product.data.name_i18n, this.$i18n.locale)
			},
			truncatedDescription() {
				const maxLength = 80;
				const description = this.getDescription();
				if (!description) {
					return null;
				}

				return description.length > maxLength ?
					Text.trimNotWithinWords( description, maxLength) + "..." : description;
			},
			productImg() {
				return this.product.data.image || BackgroundImage;
			},
		},
		methods: {
			hasDescription() {
				const descriptions = this.product.data.description_i18n;
				const locale = this.$i18n.locale;

				return Boolean(descriptions?.[locale]);
			},
			getDescription() {
				if (this.hasDescription()) {
					return this.$options.filters.translatable(
						'',
						this.product.data.description_i18n,
						this.$i18n.locale
					)?.replace(/<\/?[^>]+(>|$)/g, "")
				}
			},
			increment() {
				if (!this.product.data.max_quantity || this.currentQuantity < this.product.data.max_quantity) {
					this.emit(this.currentQuantity + 1);
				}
			},
			decrement() {
				if (this.currentQuantity > 0 && (!this.product.data.min_quantity || this.currentQuantity > this.product.data.min_quantity)) {
					this.emit(this.currentQuantity - 1);
				}
			},
			openModal() {
				this.$emit('showProductDetailModal', this.product);
			},
			emit(newQuantity) {
				this.$emit("quantityChanged", this.product, newQuantity);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.text-grey-dark {
		color: $grey-dark;
	}
	.text-grey-light {
		color: $grey-light;
	}
	.description-text {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: normal;
		color: $secondary;
		font-weight: bold;
	}
	.counter-row {
		display: flex;
		align-items: center;
		padding-right: 5px;
	}
	.counter-value {
		font-weight: bold;
	}
	.circular-button {
		border: 2px solid $grey-light;
		border-radius: 50%;
		width: 40px;
		height: 40px;
	}
	.no-border {
		border: none;
	}
	@media (max-width: 1024px) {
		.description-text, .title-text { margin-left: 15px; }
	}
</style>
