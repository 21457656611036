<template>
	<Page color="neutralLight" breadcrumbs no-sidebar>
		<v-container class="py-md-0 px-md-6">
			<!-- TITLE -->
			<v-skeleton-loader v-if="skeleton" type="heading" />
			<h2 v-else class="display-1"v-text="$options.filters.translatable(details.data.name, details.data.name_i18n)"/>

			<!-- COLUMNS -->
			<v-row class="mt-6">
				<!-- TAG DESCRIPTION -->
				<v-col cols="12" md="5">
					<Sticky class="pb-6">
						<v-skeleton-loader v-if="skeleton" type="image" />
						<v-img v-else
							:aspect-ratio="16 /9"
							:src="details.data.background"
							class="rounded-lg"/>

						<div class="mt-4">
							<v-skeleton-loader v-if="skeleton" type="paragraph" />
							<p v-else class="body-1 font-weight-light">
								<span v-html="formattedDescription" />
								<a v-if="canShowAllDesc"
									class="secondaryDark--text"
									href
									@click.stop.prevent="showAllDesc = !showAllDesc"
									v-text="showAllDesc ? $t('product.readLess') : $t('product.readMore')"/>
							</p>
						</div>
					</Sticky>
				</v-col>

				<!-- STEPS -->
				<v-col cols="12" md="7" class="d-flex flex-column">
					<!-- PRODUCTS STEP-->
						<v-skeleton-loader  v-if="skeleton" type="card"  />
						<template v-else>
							<template v-if="tagProducts.length > 0">
								<ProductCard
									v-for="(product, index) in tagProducts"
									class="mb-8"
									:key="`tagProduct-${product.data.guid}-${index}`"
									:product="product"
									:productsSelected="productsSelected"
									@quantityChanged="updateProductQuantity"
									@showProductDetailModal="openProductDetailModal"/>

								<!-- Product Modal -->
								<ProductModal
									v-if="selectedProductForDetailModal"
									:product="selectedProductForDetailModal"
									:productsSelected="productsSelected"
									:isOpen="showProductDetailModal"
									@quantityChanged="updateProductQuantity"
									@goNext="goNext"
									@addToCart="addToCart"
									@close="showProductDetailModal = false"
								/>
							</template>
							<v-alert v-else type="info" prominent text>
								<span v-text="$t('tag.noProducts')"></span>
							</v-alert>
						</template>

					<!-- CALENDAR STEP-->
					<ProductPackageSelector
						v-if="!noProduct"
						ref="ProductPackageSelector"
						:productsSelected="productsSelected"
						@addToCartSuccess="handleAddToCart"/>
				</v-col>
			</v-row>

			<StickyBottomActions
				back-icon="mdi-cart-outline"
				:next-icon="nextBtnIcon"
				:back-label="$t('tag.continueShopping')"
				:next-label="nextBtnLabel"
				:next-disabled="noProduct"
				:active="!noProduct"
				@backClicked="goBack"
				@nextClicked="goNext" />
		</v-container>
	</Page>
</template>

<script>
	import Page from '@/components/Page.vue'
	import { BaseModel, EComService, PaginationModel, ProductModel } from '@connectngo/sdk'
	import Text from '@/utils/Text'
	import { WindowWidthMixin } from '@/mixins/ProductMixin'
	import Sticky from '@/components/Sticky.vue'
	import StickyBottomActions from '@/components/Shared/StickyBottomActions'
	import ProductCard from '@/components/ProductV2/ProductCard'
	import ProductPackageSelector from '@/components/ProductV2/ProductPackageSelector'
	import ProductModal from '@/components/ProductV2/ProductDetailModal';

	export default {
		name: 'TagV2',
		components: {
			ProductCard,
			StickyBottomActions,
			Sticky,
			Page,
			ProductModal,
			ProductPackageSelector,
		},
		mixins : [WindowWidthMixin],
		data() {
			return {
				skeleton: true,
				loading: true,
				details: new BaseModel(),
				tagProducts: new PaginationModel(),
				title: '',
				showAllDesc: false,
				currentStep : null,
				steps : ['disable', 'calendar', 'checkout'],
				productsSelected : new Map(),
				noProduct : true,
				selectedProductForDetailModal: null,
				showProductDetailModal: false,
			}
		},
		metaInfo() {
			return {
				title: this.title,
				meta:  this.getMeta()
			}
		},
		computed : {
			description() {
				return this.$options.filters.translatable(
					"",
					this.details.data.description_i18n,
					this.$i18n.locale
				) || ''
			},
			canShowAllDesc() {
				return this.description.length > 200 && this.isMobile;
			},
			formattedDescription() {
				const trimmed = Text.trimNotWithinWords(
					this.description.replace(/<\/?[^>]+(>|$)/g, ""),
					200
				);
				return this.isMobile && this.canShowAllDesc && !this.showAllDesc
					? trimmed.length === this.description.length
						? trimmed
						: trimmed + "..."
					: this.description;
			},
			nextBtnLabel() {
				if (this.currentStep === this.steps[0] || this.currentStep === this.steps[1]) {
					return this.$t('tag.chooseVisitingDate')
				} else  {
					return this.$t('btn.proceedCheckout')
				}
			},
			nextBtnIcon() {
				if (this.currentStep === this.steps[0] || this.currentStep === this.steps[1]) {
					return 'mdi-calendar-blank';
				} else  {
					return 'mdi-credit-card-outline';
				}
			},
		},
		created () {
			this.title = this.$i18n.t('route.tags')
			this.$route.meta.name = this.$i18n.t('state.loading')
			this.currentStep = this.steps.find((element) => element = 'disable');
			this.getTag()
		},
		watch: {
			productsSelected() {
				if (this.productsSelected.size > 0) {
					this.currentStep = this.steps[1];
				}
			}
		},
		methods: {
			finalizeGetTag() {
				this.loading = false

				//check for product slug, so we can open modal if available
				const slug = this.$route.params.slug || window.location.hash.slice(1);
				if (slug) {
					const product = this.tagProducts.find(p => p.data.slug === slug);
					if (product) {
						this.openProductDetailModal(product);
					}
				}
			},
			getTag () {
				this.loading = true

				return new EComService().getTagDetails(this.$route.params.key)
					.then((tag) => {
						// Prevent navigation for reload tag from slug
						if (tag.data.reloadable) {
							return this.$router.push({ name: 'reloadV2' })
						}

						this.details = tag;
						this.title = this.$options.filters.translatable(
							tag.data.name,
							tag.data.name_i18n
						);

						this.$route.meta.name = this.title;
						this.$breadcrumbs.refresh();

						this.tagProducts = tag.data.products
							.map(product => new ProductModel(product.data))

						this.skeleton = false;
					})
					.catch((error) => {
						if(error.response?.status === 404) {
							this.$router.push({ name: "error404" });
						} else {
							this.$handleError(this, error)
						}
					})
					.finally(this.finalizeGetTag)
			},
			getMeta() {
				return [
					{
						property: 'description',
						content: this.$options.filters.translatable(
							this.details.data.description,
							this.details.data.description_i18n,
							this.$i18n.locale
						),
					},
					{
						property: 'og:title',
						content: this.$options.filters.translatable(
							this.details.data.name,
							this.details.data.name_i18n,
							this.$i18n.locale
						),
					},
					{ property: 'og:url', content: window.location.href },
					{
						property: 'og:description',
						content: this.$options.filters.translatable(
							this.details.data.description,
							this.details.data.description_i18n,
							this.$i18n.locale
						),
					},
					{ property: 'og:image', content: this.details.data.background },
					{
						property: 'twitter:image:src',
						content: this.details.data.background,
					},
				];
			},
			addToCart() {
				this.$refs.ProductPackageSelector.addToCart();
			},
			goNext() {
				//TODO modify when products added to cart
				if (this.currentStep == this.steps[0]) {
					return;
				} else if(this.currentStep == this.steps[1]){
					this.$refs.ProductPackageSelector.openModal();
				} else if(this.currentStep == this.steps[2]) {
					this.$router.push({ name: "cart" });
				}
			},
			goBack() {
				this.$router.push({name : 'home'});
			},
			updateProductQuantity(product, quantity) {
				if (quantity > 0) {
					this.productsSelected.set(product.data.id, {id: product.data.id, product, quantity });
				} else {
					this.productsSelected.delete(product.data.id);
				}
				this.noProduct = this.productsSelected.size === 0;
				// Vue is a b***h with the damn map recognition so force it to know what's up
				this.productsSelected = new Map(this.productsSelected);
			},
			openProductDetailModal(product) {
				history.pushState(null, null, `#${product.data.slug}`);
				this.selectedProductForDetailModal = product;
				this.showProductDetailModal = false;

				this.$nextTick(() => {
					this.showProductDetailModal = true;
				});
			},
			handleAddToCart(cartData) {
				this.currentStep = this.steps[2];
				this.productsSelected = new Map();
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>
