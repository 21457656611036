<template>
	<div :class="['sticky-container rounded', active ? 'active' : '']">
		<v-row>
			<v-col cols="12" md="5" v-if="!isMobile">
				<v-btn color="button"
					block
					outlined
					x-large
					style="background-color: white"
					class="text-capitalize-fl"
					:disabled="backDisabled"
					@click="$emit('backClicked')">
					<v-icon left>{{backIcon}}</v-icon>
					<span v-text="backLabel"></span>
				</v-btn>
			</v-col>

			<v-col cols="12" md="7">
				<v-btn color="button"
					block
					x-large
					class="lowerCaseBtn"
					:disabled="nextDisabled"
					@click="$emit('nextClicked')">
					<v-icon left>{{nextIcon}}</v-icon>
					<span v-text="nextLabel"></span>
				</v-btn>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name : 'StickyBottomActions',
	props : {
		active : {
			type : Boolean,
			default : false,
		},
		nextLabel : {
			type : String,
			required : true,
		},
		nextIcon : {
			type : String,
			required : true,
		},
		nextDisabled : {
			type : Boolean,
			default : false,
		},
		backLabel : {
			type : String,
			required : true,
		},
		backIcon : {
			type : String,
			required : true,
		},
		backDisabled : {
			type : Boolean,
			default : false,
		},
	},
	data() {
		return {
			windowWidth: window.innerWidth, // initial width
		};
	},
	computed: {
		isMobile() {
			// Définir la largeur maximale pour le mode mobile
			return this.windowWidth <= 640;
		},
	},
	created() {
		window.addEventListener('resize', this.updateWindowWidth);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateWindowWidth);
	},
	methods: {
		updateWindowWidth() {
			this.windowWidth = window.innerWidth;
		},
	},
}
</script>


<style scoped lang="scss">
	.sticky-container {
		position: relative;
		background-color: transparent;
	}
	.sticky-container.active{
		position: sticky;
		position: -webkit-sticky;
		bottom: 0%;
		z-index: 2;
	}
	.sticky-container .button-container v-btn {
		width: 50%;
	}
	@media all and (max-width: 620px) {
		.sticky-container .button-container button, .sticky-container .button-container a{
			font-size: 0.7rem;
			padding: 0;
		}
	}
	@media all and (max-width: 430px) {
		.sticky-container .button-container {
			display: grid;
		}
		.sticky-container .button-container button{
			margin-left: 0 !important;
			margin-top: 10px;
		}
	}
</style>
