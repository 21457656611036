<template>
	<v-card class="primary defaultBorder">
		<v-skeleton-loader v-if="skeleton" type="list-item,list-item,list-item" />
		<v-form id="giftCardForm-1" v-else-if="step === 'start'" :disabled="loading" data-test-selector="gift_card_form_start" v-model="formStartValid" @submit.prevent="handleContinueClick">
			<v-card>
				<v-card-title v-text="$t('cart.gift.enterDetails')"></v-card-title>
				<v-card-text>
					<label v-html="getGiftCardNumberLabel" for="giftCardNumber"></label>
					<v-text-field
						id="giftCardNumber"
						name="number"
						v-model="data.giftCard.number"
						:rules="[rules.required]"
						:label="$t('input.number.placeholder')"
						:error-messages="formErrors.number"
						@input="formErrors = {}"
						:disabled="loading"
						outlined
					>
					</v-text-field>

					<label v-html="getGiftCardSerialLabel" for="giftCardSerial"></label>
					<v-text-field
						id="giftCardSerial"
						name="serial"
						v-model="data.giftCard.serial"
						:label="$t('input.number.placeholder')"
						:error-messages="formErrors.serial"
						@input="formErrors = {}"
						:disabled="loading"
						outlined
					>
					</v-text-field>

					<CheckBoxTermsConditions :readTerms="data.readTerms" :loading="loading" />

					<v-row style="padding: 0 10px 10px;" justify="end">
						<v-btn
							class="mt-4 cancelBtn"
							color="primary"
							x-large
							text
							@click="handleCancelClick"
							outlined
						>
							<span v-text="$t('btn.cancel')"></span>
						</v-btn>

						<v-btn
							class="mt-4 white--text"
							type="submit"
							color="button"
							:disabled="!canContinue"
							:loading="loading"
							x-large
						>
							<span v-text="$t('btn.confirm')"></span>
						</v-btn>
					</v-row>
				</v-card-text>
			</v-card>
		</v-form>

		<v-form id="giftCardForm-2" v-else-if="step === 'summary'" :disabled="loading" data-test-selector="gift_card_form_summary" v-model="formSummaryValid" @submit.prevent="handleContinueClick" lazy-validation>
			<v-card>
				<v-card-text>
					<label v-text="$t('cart.gift.current.balance') + ' ' + details.data.uid"></label>
					<div class="emptySpace">&nbsp;</div>
					<label class="currentBalance" v-text="$options.filters.currencyWithoutCountry(details.data.balance)"></label>
				</v-card-text>
				<v-card-text>
					<label v-text="$t('cart.gift.balance.to.use')" for="giftCardBalanceToUse"></label>
					<v-text-field
						id="giftCardBalanceToUse"
						name="useBalance"
						type="number"
						:prefix="'$'"
						v-model="data.giftCard.balance"
						:rules="[rules.required, rules.balance, rules.summary, rules.notNegative, rules.notZero]"
						:error-messages="formErrors.useBalance"
						@input="formErrors = {}"
						:disabled="loading"
						outlined
					>
					</v-text-field>

					<CheckBoxTermsConditions :readTerms="data.readTerms" :loading="loading" />

					<div class="g-recaptcha"
						v-if="enableCaptcha"
						data-callback="onReCaptchaSuccess"
						:data-sitekey="$root.websiteConfig.data.gcp_recaptcha"
						data-action="PAYMENT"
					>
					</div>

					<v-row style="padding: 0 10px 10px;" justify="end">
						<v-btn
							class="mt-4 cancelBtn"
							color="primary"
							x-large
							text
							@click="handleCancelClick"
							outlined
						>
							<span v-text="$t('btn.cancel')"></span>
						</v-btn>

						<v-btn
							class="mt-4 white--text"
							type="submit"
							color="button"
							:disabled="!canContinue"
							:loading="loading"
							x-large
						>
							<span v-text="$t('btn.apply')"></span>
						</v-btn>
					</v-row>
				</v-card-text>
			</v-card>
		</v-form>
	</v-card>
</template>

<style scoped>
	.defaultBorder {
		border: 2px solid;
	}

	form[id^='giftCardForm'] {
		.v-card__title,
		label {
			color: #383838;
		}

		label.currentBalance {
			font-size: 27px;
			color: #4C4C4C;
			padding-left: 15px;
		}

		.g-recaptcha {
			margin-bottom: 10px;
		}

		button {
			width: 108px;
			height: 48px;
			padding: 0 20px;
			text-transform: none;
		}

		.v-card__title,
		button,
		label {
			font-weight: 600;
			font-size: 18px;
		}

		button:not(:last-child) {
			margin-right: 10px;
		}

		.cancelBtn {
			width: 96px !important;
			border-style: solid;
			border-width: 1px;
			border-color: inherit !important;
		}
	}
</style>

<script>
	import Vue from "vue";
	import {
		BaseModel,
		Rules,
		CartModel,
		EventBus,
		PaymentService,
		PaymentModel,
	} from "@connectngo/sdk";
	import CaptchaMixin from "@/mixins/CaptchaMixin";
	import ReloadV2Mixin from '@/mixins/ReloadV2Mixin';
	import CheckBoxTermsConditions from '@/components/Inputs/CheckBoxTermsConditions.vue';

	export default {
		name: "PaymentGiftCardForm",
		mixins: [CaptchaMixin, ReloadV2Mixin],
		components: {
			CheckBoxTermsConditions,
		},
		props: {
			skeleton: {
				type: Boolean,
				default: false,
			},
			component: {
				type: Vue.component,
				default: null,
			},
			cart: {
				type: CartModel,
				default: () => new CartModel(),
			},
			payments: {
				type: PaymentModel,
				default: () => new PaymentModel(),
			},
			engine: {
				type: BaseModel,
				default: () => new BaseModel(),
			},
			cashlessEngine: {
				type: BaseModel,
				default: () => new BaseModel(),
			},
		},
		data: () => ({
			loading: false,
			paying: false,
			success: false,
			error: false,
			step: "start",
			formStartValid: false,
			formSummaryValid: false,
			details: new BaseModel(),
			data: {
				readTerms: false,
				giftCard: {
					number: "",
					serial: "",
					balance: 0,
				},
			},
			cashlessPaymentId: 0,
			cashlessPaymentAmount: 0.00,
			steps: ["start", "summary"],
			formErrors: {},
			rules: {},
			savingInvoice: false,
			balanceRemainingToPay : 0,
		}),
		watch: {
			step() {
				this.loadCaptcha();
			},
		},
		computed: {
			getGiftCardNumberLabel() {
				return this.$t('giftCard.inputNumber') + ' <span style="color: red;">' + this.suffixRequired + '</span>';
			},
			getGiftCardSerialLabel() {
				return this.$t('giftCard.inputSerial') + ' <span style="color: grey; opacity: 0.5;">' + this.suffixOptional + '</span>';
			},
			canContinue() {
				switch (this.step) {
					case "start":
						return this.formStartValid && !this.loading;
					case "summary":
						return this.formSummaryValid && !this.loading;
				}
				return true;
			},
			suffixOptional() {
				if (this.data.giftCard.serial === "") {
					return `(${this.$t("suffix.optional")})`;
				}

				return "";
			},
			suffixRequired() {
				if (this.data.giftCard.number === "") {
					return `(${this.$t("suffix.required")})`;
				}

				return "";
			},
		},
		created() {
			this.balanceRemainingToPay = this.cart.data.amountToPay;
			this.rules = {
				required: (value) =>
					Rules.required(value) || this.$t("rules.required"),
				balance: (value) =>
					value <= this.details.data.balance || this.$t("rules.balance"),
				summary: (value) =>
					value <= this.payments.data.balance ||
					value === 0 ||
					this.$t("rules.summary"),
				notNegative: (value) => value >= 0 || this.$t("rules.notNegative"),
				notZero: (value) => value > 0 || this.$t("rules.notZero"),
			};
		},
		methods: {
			handleContinueClick() {
				this.continue();
			},
			handleCancelClick() {
				this.$emit("cancel");
			},
			continue() {
				switch (this.step) {
					case "start":
						this.loading = true;
						new PaymentService()
							.getCashlessBalance(
								this.data.giftCard.number,
								this.data.giftCard.serial,
								this.cashlessEngine.data.id,
								this.cart.data.uuid
							)
							.then((details) => {
								this.details = details;
								this.step = "summary";

								if (this.details.data.balance >= this.cart.data.amountToPay) {
									this.data.giftCard.balance = this.cart.data.amountToPay;
								} else {
									this.data.giftCard.balance = this.details.data.balance
								}
							})
							.catch((error) => this.$handleError(this, error))
							.finally(() => (this.loading = false));
						break;
					case "summary":
						this.pay();
						break;
				}
			},
			pay() {
				this.loading = true;
				this.paying = true;
				Promise.all([
					new PaymentService()
						.processCashless(
							this.data.giftCard.number,
							this.data.giftCard.serial,
							this.cashlessEngine.data.id,
							this.cart.data.uuid,
							this.data.giftCard.balance
						),
					])
					.then((response) => {
						this.cashlessPaymentId = response[0].data.paymentId;
						this.cashlessPaymentAmount = parseFloat(this.data.giftCard.balance);

						const amountRemaining = this.payments.data.balance - parseFloat(this.data.giftCard.balance)
						this.balanceRemainingToPay = amountRemaining;
						this.cart.data.amountToPay = amountRemaining;
						this.cart.data.total = this.cart.data.amountToPay;

						EventBus.publish('CART_UPDATED', this.cart);

						this.$snack(this.$i18n.t('cart.gift.applied'));

						if (amountRemaining === 0) {
							this.$emit('completed')
						} else {
							this.$emit('partialGiftCardPaymentCompleted')
						}
					})
					.catch((error) => {
						this.error = true;
						this.$handleError(this, error, this.formErrors);
					})
					.finally(() => {
						this.loading = false;
						this.paying = false;
					});
			},
		},
	};
</script>
